define("discourse/plugins/discourse-customer-flair-plugin/discourse/connectors/topic-list-before-relative-date/last-whispered-at", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "discourse/plugins/discourse-customer-flair-plugin/discourse/lib/slo-status", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _sloStatus, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WhisperIndicator extends _component.default {
    static shouldRender(args) {
      return args.topic.get("slo_time");
    }
    get topic() {
      return this.args.outletArgs.topic;
    }
    get sloStatus() {
      return (0, _sloStatus.default)(this.topic);
    }
    get displayIcon() {
      if (!this.sloStatus) {
        return null;
      }
      const lastWhisperedAt = this.topic.get("last_whispered_at");
      const lastRespondedAt = this.topic.get("last_responded_at");
      if (lastWhisperedAt && (!lastRespondedAt || moment(lastWhisperedAt).isAfter(moment(lastRespondedAt)))) {
        return true;
      }
    }
    get iconTitle() {
      if (this.displayIcon) {
        const lastWhisperedAt = this.topic.get("last_whispered_at");
        return `Whispered ${moment(lastWhisperedAt).fromNow()}`;
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.displayIcon}}
          {{icon "far-eye-slash" translatedTitle=this.iconTitle}}
        {{/if}}
      
    */
    {
      "id": "XjbhbYr6",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"displayIcon\"]],[[[1,\"      \"],[1,[28,[32,0],[\"far-eye-slash\"],[[\"translatedTitle\"],[[30,0,[\"iconTitle\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-customer-flair-plugin/discourse/connectors/topic-list-before-relative-date/last-whispered-at.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = WhisperIndicator;
});