define("discourse/plugins/discourse-customer-flair-plugin/discourse/raw-views/priority-column", ["exports", "@ember/object", "discourse/lib/icon-library", "discourse-i18n", "discourse/plugins/discourse-customer-flair-plugin/discourse/raw-views/slo-object"], function (_exports, _object, _iconLibrary, _discourseI18n, _sloObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PriorityColumn extends _sloObject.default {
    get emoji() {
      const sloPlan = this.get("topic.slo_plan");
      const sloHours = this.get("topic.slo_hours");
      const sloTime = this.get("sloTime");
      const sloStatus = this.get("sloStatus");
      const assignedToUsername = this.get("topic.assigned_to_user.username");
      const assignedToGroupName = this.get("topic.assigned_to_group.name");
      const currentUser = this.currentUser;
      let cssClass, icon, title;
      if (!sloTime) {
        return null;
      }
      if (assignedToUsername && assignedToUsername !== currentUser.username) {
        cssClass = "assigned-to-user";
      } else if (assignedToGroupName && currentUser.groups.any(g => g.name === assignedToGroupName)) {
        cssClass = "assigned-to-group";
      }
      if (sloStatus === this.SLO_PASSED) {
        icon = "angles-up";
        title = (0, _discourseI18n.i18n)("support_column.slo_passed", {
          plan: sloPlan,
          count: sloHours,
          at: sloTime.fromNow()
        });
        cssClass ||= "heatmap-high";
      } else if (sloStatus === this.SLO_APPROACHING) {
        icon = "angle-up";
        title = (0, _discourseI18n.i18n)("support_column.slo_approaching", {
          plan: sloPlan,
          count: sloHours,
          at: sloTime.fromNow()
        });
        cssClass ||= "heatmap-med";
      }
      if (icon) {
        return (0, _iconLibrary.iconHTML)(icon, {
          translatedTitle: title,
          class: cssClass
        });
      }
      return null;
    }
    static #_ = (() => dt7948.n(this.prototype, "emoji", [(0, _object.computed)("topic.slo_plan", "topic.slo_hours", "sloTime", "sloStatus", "topic.assigned_to_user.username", "currentUser.username")]))();
  }
  _exports.default = PriorityColumn;
});