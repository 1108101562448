define("discourse/plugins/discourse-customer-flair-plugin/discourse/components/priority-cell", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/d-icon", "discourse-i18n", "discourse/plugins/discourse-customer-flair-plugin/discourse/lib/slo-status", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _discourseI18n, _sloStatus, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PriorityCell extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get topic() {
      return this.args.topic;
    }
    get sloTime() {
      const raw = this.topic.get("slo_time");
      return raw ? moment(raw) : null;
    }
    get sloStatus() {
      return (0, _sloStatus.default)(this.topic);
    }
    get emoji() {
      const sloPlan = this.topic.get("slo_plan");
      const sloHours = this.topic.get("slo_hours");
      const assignedToUsername = this.topic.get("assigned_to_user.username");
      const assignedToGroupName = this.topic.get("assigned_to_group.name");
      const currentUser = this.currentUser;
      let cssClass, iconName, title;
      if (!this.sloTime) {
        return null;
      }
      if (assignedToUsername && assignedToUsername !== currentUser.username) {
        cssClass = "assigned-to-user";
      } else if (assignedToGroupName && currentUser.groups.any(g => g.name === assignedToGroupName)) {
        cssClass = "assigned-to-group";
      }
      if (this.sloStatus === _sloStatus.SLO_PASSED) {
        iconName = "angles-up";
        title = (0, _discourseI18n.i18n)("support_column.slo_passed", {
          plan: sloPlan,
          count: sloHours,
          at: this.sloTime.fromNow()
        });
        cssClass ||= "heatmap-high";
      } else if (this.sloStatus === _sloStatus.SLO_APPROACHING) {
        iconName = "angle-up";
        title = (0, _discourseI18n.i18n)("support_column.slo_approaching", {
          plan: sloPlan,
          count: sloHours,
          at: this.sloTime.fromNow()
        });
        cssClass ||= "heatmap-med";
      }
      if (iconName) {
        return (0, _dIcon.default)(iconName, {
          translatedTitle: title,
          class: cssClass
        });
      }
      return null;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <td class="priority">
          {{this.emoji}}
        </td>
      
    */
    {
      "id": "Bo2wyuPT",
      "block": "[[[1,\"\\n    \"],[10,\"td\"],[14,0,\"priority\"],[12],[1,\"\\n      \"],[1,[30,0,[\"emoji\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-customer-flair-plugin/discourse/components/priority-cell.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PriorityCell;
});